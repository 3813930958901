import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from '../img/logo.png';
import './NavLinks.css';
import LogoTransition from '../components/Logo-hover';

function BasicExample() {
  return (
    <Navbar className="bg-body-tertiary">
        <Container>
          <Navbar.Brand href="#home">
            <LogoTransition />
          </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link className="navlink" href="#home">Home</Nav.Link>
            <Nav.Link className="navlink" href="#about">About</Nav.Link>
            <Nav.Link className="navlink" href="#services">Services</Nav.Link>
            <Nav.Link className="navlink" href="#contact">Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
        </Container>
      </Navbar>
  );
}

export default BasicExample;
