import React, { useState } from 'react';
import FormCard from '../components/FormCard';
import axios from 'axios';
import { GoogleLogin } from '@react-oauth/google';

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSignIn = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('http://localhost:8000/users/login/', {
        email,
        password,
      });
      console.log('Login successful', response.data);
    } catch (err) {
      setError('Login Failed. Please check your credentials');
      console.error('Error loggining in:', err);
    }
  };

  const handleGoogleSuccess = async (credentialResponse) => {
    try {
      const response = await axios.post('https://bookifysa.me/users/accounts/login/', {
        token: credentialResponse.credential,
      });
      console.log('Google login successful:', response.data);
    } catch (err) {
      console.error('Error with Google Login:', err);
    }
  };

  const handleGoogleFailure = (error) => {
    console.error('Google login failed:', error);
  };

  const fields = [
    {
      id: 'email',
      label: 'Email address',
      type: 'email',
      placeholder: 'Enter email address',
      value: email,
      onChange: (e) => setEmail(e.target.value),
      required: true,
    },
    {
      id: 'password',
      label: 'Password',
      type: 'password',
      placeholder: 'Password',
      value: password,
      onChange: (e) => setPassword(e.target.value),
      required: true,
    },
  ]

  return (
    <>
      
      <FormCard title="Sign In" fields={fields} buttonText="Sign In" onSubmit={handleSignIn} />
      {error && <p className="text-danger mt-3">{error}</p>}
      <div className="mt-3">
        <GoogleLogin
          onSuccess={handleGoogleSuccess}
          onError={handleGoogleFailure}
        />
      </div>
    </>
  );
};

export default SignIn;
