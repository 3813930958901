import React from 'react';
import { Card, Form, Button } from 'react-bootstrap';

const FormCard = ({ title, fields, buttonText, onSubmit }) => {
    return (
        <Card style={{ width: '30rem', margin: '2rem auto' }}>
            <Card.Body>
                <Card.Title>{title}</Card.Title>
                <Form onSubmit={onSubmit}>
                    {fields.map((field, index) => (
                        <Form.Group key={index} className="mb-3" controlId={field.id}>
                            <Form.Label>{field.label}</Form.Label>
                            <Form.Control
                                type={field.type}
                                placeholder={field.placeholder}
                                value={field.value}
                                onChange={field.onChange}
                                required={field.required}
                            />
                        </Form.Group>
                    ))}
                    <Button variant="primary" type="submit">
                      {buttonText}
                    </Button>
                </Form>
            </Card.Body>
        </Card>
    )
};

export default FormCard;
