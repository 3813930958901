import React, { useState } from 'react';
import { motion } from 'framer-motion';
import image1 from '../img/logo-favicon.png';
import image2 from '../img/logo.png';


function LogoTransition() {
  const [hovered, setHovered] = useState(false);

  return (
    <motion.img
      src={hovered ? image2 : image2}
      alt="Logo"
      width="60"
      height="45"
      initial={{ opacity: 0.8 }}
      animate={{ opacity: 1 }}
      whileHover={{ scale: 0.9 }}
      transition={{ duration: 0.3 }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={{
        width: "133px",
        height: "auto"
      }}
    />
  );
};

export default LogoTransition;
